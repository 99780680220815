<template>
    <div class="CEESAR-Form">
        <div class="CEESAR-FormTitle">{{title}}</div>
        
        <div class="CEESAR-FormContent">
            <slot name="Content"/>
        </div>

        <div class="CEESAR-FormButtons">
            <slot name="Buttons"/>
        </div>

        <div class="CEESAR-FormDialogs">
            <slot name="Dialogs"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "CEESAR-Form",
    props:{
        title:{type: String, required: true}
    }
}
</script>

<style>

</style>